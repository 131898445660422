import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/1080p_Series_CGI_List/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "1080p CGI Commands",
  "path": "/1080p_Series_CGI_List/Alarm_Menu/Schedule/",
  "dateChanged": "2018-03-23",
  "author": "Mike Polinowski",
  "excerpt": "Camera API control for developers",
  "image": "../../AU_SearchThumb_CGICommands_1080p.png",
  "social": "/images/Search/AU_SearchThumb_CGICommands_1080p.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_CGIs_white.webp",
  "chapter": "1080p CGI Commands"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='1080p CGI Commands' dateChanged='2018-03-26' author='Mike Polinowski' tag='INSTAR IP Camera' description='Camera API control for developers' image='/images/Search/AU_SearchThumb_CGICommands_1080p.png' twitter='/images/Search/AU_SearchThumb_CGICommands_1080p.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/1080p_Serie_CGI_Befehle/Alarm_Menu/Zeitplan/' locationFR='/fr/1080p_Series_CGI_List/Alarm_Menu/Schedule/' crumbLabel="1080p CGI Commands" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "the-alarm-menu--schedule",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#the-alarm-menu--schedule",
        "aria-label": "the alarm menu  schedule permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The Alarm Menu :: Schedule`}</h1>
    <hr></hr>
    <EuiSpacer mdxType="EuiSpacer" />
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#the-alarm-menu--schedule"
        }}>{`The Alarm Menu :: Schedule`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#paramcgicmdgetscheduleex-enamemd"
            }}>{`param.cgi?cmd=getscheduleex&-ename=md`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#paramcgicmdgetbackupschedule-enamemd"
            }}>{`param.cgi?cmd=getbackupschedule&-ename=md`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#paramcgicmdgetplancgi"
            }}>{`param.cgi?cmd=getplancgi`}</a></li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <h2 {...{
      "id": "paramcgicmdgetscheduleex-enamemd",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#paramcgicmdgetscheduleex-enamemd",
        "aria-label": "paramcgicmdgetscheduleex enamemd permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=getscheduleex&-ename=md`}</h2>
    <h3 {...{
      "id": "set-motion-detection-time-schedule-parameter",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#set-motion-detection-time-schedule-parameter",
        "aria-label": "set motion detection time schedule parameter permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Set Motion Detection Time Schedule Parameter`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`week0`}</code>{`: Sunday：every 24 hrs divide to half hr is a segment , use P or N to indicate if the alarm is active - `}{`[P]`}{` means active , `}{`[N]`}{` means inactive`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`week1`}</code>{`: Monday：every 24 hrs divide to half hr is a segment , use P or N to indicate if the alarm is active - `}{`[P]`}{` means active , `}{`[N]`}{` means inactive`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`week2`}</code>{`: Tuesday：every 24 hrs divide to half hr is a segment , use P or N to indicate if the alarm is active - `}{`[P]`}{` means active , `}{`[N]`}{` means inactive`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`week3`}</code>{`: Wednesday：every 24 hrs divide to half hr is a segment , use P or N to indicate if the alarm is active - `}{`[P]`}{` means active , `}{`[N]`}{` means inactive`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`week4`}</code>{`: Thursday：every 24 hrs divide to half hr is a segment , use P or N to indicate if the alarm is active - `}{`[P]`}{` means active , `}{`[N]`}{` means inactive`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`week5`}</code>{`: Friday：every 24 hrs divide to half hr is a segment , use P or N to indicate if the alarm is active - `}{`[P]`}{` means active , `}{`[N]`}{` means inactive`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`week6`}</code>{`: Saturday：every 24 hrs divide to half hr is a segment , use P or N to indicate if the alarm is active - `}{`[P]`}{` means active , `}{`[N]`}{` means inactive`}</li>
    </ul>
    <h3 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`getscheduleex`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-ename`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`md
var `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`week0`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`var `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`week1`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`var `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`week2`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`var `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`week3`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`var `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`week4`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`var `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`week5`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`var `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`week6`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

GET: http://192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`getscheduleex`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-ename`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`md`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-usr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-pwd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar
var `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`week0`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`var `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`week1`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`var `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`week2`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`var `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`week3`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`var `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`week4`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`var `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`week5`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`var `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`week6`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

SET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setscheduleex`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-ename`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`md`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week0`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week1`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNPPPPPPPPPPPPPPPPPPPPPPNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week2`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNPPPPPPPPPPPPPPPPPPPPPPNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week3`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNPPPPPPPPPPPPPPPPPPPPPPNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week4`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNPPPPPPPPPPPPPPPPPPPPPPNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week5`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNPPPPPPPPPPPPPPPPPPPPPPNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week6`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN`}</code></pre></div>
    <p>{`This CGI command sets the active motion detection schedule. The schedule that is set inside your WebUI is the back-up schedule (see below). When you deactivate the alarm inside the webUI via the bell icon the active schedule will be stored inside the back-up schedule and afterwards replaced with an empty schedule. Clicking on the bell icon again will reverse this and set your old custom schedule.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "paramcgicmdgetbackupschedule-enamemd",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#paramcgicmdgetbackupschedule-enamemd",
        "aria-label": "paramcgicmdgetbackupschedule enamemd permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=getbackupschedule&-ename=md`}</h2>
    <h3 {...{
      "id": "set-motion-detection-time-back-up-schedule-parameter",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#set-motion-detection-time-back-up-schedule-parameter",
        "aria-label": "set motion detection time back up schedule parameter permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Set Motion Detection Time Back-UP Schedule Parameter`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`week0`}</code>{`: Sunday：every 24 hrs divide to half hr is a segment , use P or N to indicate if the alarm is active - `}{`[P]`}{` means active , `}{`[N]`}{` means inactive`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`week1`}</code>{`: Monday：every 24 hrs divide to half hr is a segment , use P or N to indicate if the alarm is active - `}{`[P]`}{` means active , `}{`[N]`}{` means inactive`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`week2`}</code>{`: Tuesday：every 24 hrs divide to half hr is a segment , use P or N to indicate if the alarm is active - `}{`[P]`}{` means active , `}{`[N]`}{` means inactive`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`week3`}</code>{`: Wednesday：every 24 hrs divide to half hr is a segment , use P or N to indicate if the alarm is active - `}{`[P]`}{` means active , `}{`[N]`}{` means inactive`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`week4`}</code>{`: Thursday：every 24 hrs divide to half hr is a segment , use P or N to indicate if the alarm is active - `}{`[P]`}{` means active , `}{`[N]`}{` means inactive`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`week5`}</code>{`: Friday：every 24 hrs divide to half hr is a segment , use P or N to indicate if the alarm is active - `}{`[P]`}{` means active , `}{`[N]`}{` means inactive`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`week6`}</code>{`: Saturday：every 24 hrs divide to half hr is a segment , use P or N to indicate if the alarm is active - `}{`[P]`}{` means active , `}{`[N]`}{` means inactive`}</li>
    </ul>
    <h3 {...{
      "id": "example-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example-1",
        "aria-label": "example 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: `}<span parentName="code" {...{
            "className": "token variable"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`\``}</span>{`http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{`getbackupschedule`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`&`}</span>{`-ename`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{`md`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`\``}</span></span>{`

GET: `}<span parentName="code" {...{
            "className": "token variable"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`\``}</span>{`http://192.168.2.168/param.cgi?cmd`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{`getbackupschedule`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`&`}</span>{`-ename`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{`md`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`&`}</span>{`-usr`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{`admin`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`&`}</span>{`-pwd`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{`instar`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`\``}</span></span>{`

SET: `}<span parentName="code" {...{
            "className": "token variable"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`\``}</span>{`http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{`setbackupschedule`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`&`}</span>{`-ename`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{`md`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`&`}</span>{`-week0`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{`NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`&`}</span>{`-week1`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{`NNNNNNNNNNNNNNNNPPPPPPPPPPPPPPPPPPPPPPNNNNNNNNNN`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`&`}</span>{`-week2`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{`NNNNNNNNNNNNNNNNPPPPPPPPPPPPPPPPPPPPPPNNNNNNNNNN`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`&`}</span>{`-week3`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{`NNNNNNNNNNNNNNNNPPPPPPPPPPPPPPPPPPPPPPNNNNNNNNNN`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`&`}</span>{`-week4`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{`NNNNNNNNNNNNNNNNPPPPPPPPPPPPPPPPPPPPPPNNNNNNNNNN`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`&`}</span>{`-week5`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{`NNNNNNNNNNNNNNNNPPPPPPPPPPPPPPPPPPPPPPNNNNNNNNNN`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`&`}</span>{`-week6`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{`NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`\``}</span></span></code></pre></div>
    <p>{`If you set the schedule through an automation system / smarthome, we recommend that you combine both the active and backup schedule in your SET command. This makes sure that you don't get out of sync:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setbackupschedule`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-ename`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`md`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week0`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week1`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNPPPPPPPPPPPPPPPPPPPPPPNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week2`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNPPPPPPPPPPPPPPPPPPPPPPNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week3`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNPPPPPPPPPPPPPPPPPPPPPPNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week4`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNPPPPPPPPPPPPPPPPPPPPPPNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week5`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNPPPPPPPPPPPPPPPPPPPPPPNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week6`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setscheduleex`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-ename`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`md`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week0`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week1`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNPPPPPPPPPPPPPPPPPPPPPPNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week2`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNPPPPPPPPPPPPPPPPPPPPPPNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week3`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNPPPPPPPPPPPPPPPPPPPPPPNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week4`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNPPPPPPPPPPPPPPPPPPPPPPNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week5`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNPPPPPPPPPPPPPPPPPPPPPPNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week6`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN`}</code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "paramcgicmdgetplancgi",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#paramcgicmdgetplancgi",
        "aria-label": "paramcgicmdgetplancgi permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=getplancgi`}</h2>
    <h3 {...{
      "id": "set-timer-parameter",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#set-timer-parameter",
        "aria-label": "set timer parameter permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Set Timer Parameter`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`plancgi_enable_[0 - 19]`}</code>{`: De/Activate Switch Event at Time - `}{`[0 - 86399]`}{` in seconds = 0:00:00 - 23:59:59 o´clock`}</li>
    </ul>
    <h3 {...{
      "id": "example-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example-2",
        "aria-label": "example 2 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: `}<span parentName="code" {...{
            "className": "token variable"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`\``}</span>{`http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{`getplancgi`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`\``}</span></span>{`
GET: `}<span parentName="code" {...{
            "className": "token variable"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`\``}</span>{`http://192.168.2.168/param.cgi?cmd`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{`getplancgi`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`&`}</span>{`-usr`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{`admin`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`&`}</span>{`-pwd`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{`instar`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`\``}</span></span>{`
SET: `}<span parentName="code" {...{
            "className": "token variable"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`\``}</span>{`http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{`setplancgi`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`&`}</span>{`-plancgi_enable_3`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token number"
            }}>{`0`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`&`}</span>{`-plancgi_enable_4`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token number"
            }}>{`0`}</span><span parentName="span" {...{
              "className": "token variable"
            }}>{`\``}</span></span></code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      